import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useAuthStore } from '@/store/auth'
import { WebTrackService } from '@/modules/pages/webTrack/serviceCreate'
import { IWebTrackRoute } from '@/modules/pages/webTrack/type'

const routes: Array<RouteRecordRaw> = [
	{
		path: '',
		redirect: '/account/index',
		component: () => import('@/modules/layouts/main.vue'),
		children: [
			{ path: '/account/index', component: () => import('@/modules/pages/account/index/index.vue'), meta: { auth: true, webTrack: { moduleName: 'account', functionName: 'accountOpenIndex', needTraceId: false } } },
			{ path: '/account/page/:id?', component: () => import('@/modules/pages/account/page/page.vue'), meta: { auth: true, webTrack: { moduleName: 'account', functionName: 'accountOpenPage', needTraceId: true } } },
			{ path: '/user/index', component: () => import('@/modules/pages/user/index/index.vue'), meta: { auth: true, webTrack: { moduleName: 'user', functionName: 'userOpenIndex', needTraceId: false } } },
			{ path: '/user/page/:id?', component: () => import('@/modules/pages/user/page/page.vue'), meta: { auth: true, webTrack: { moduleName: 'user', functionName: 'userOpenPage', needTraceId: true } } },
			{ path: '/wallet/petition/index', component: () => import('@/modules/pages/petition/index/page.vue'), meta: { auth: true, webTrack: { moduleName: 'petition', functionName: 'petitionOpenIndex', needTraceId: false } } },
			{ path: '/wallet/petition/page/:id?', component: () => import('@/modules/pages/petition/page/page.vue'), meta: { auth: true, webTrack: { moduleName: 'petition', functionName: 'petitionOpenPage', needTraceId: true } } },
			{ path: '/wallet/generation/index', component: () => import('@/modules/pages/walletGeneration/index/page.vue'), meta: { auth: true, webTrack: { moduleName: 'wallet', functionName: 'generation', needTraceId: false } } },
			{ path: '/notifications', component: () => import('@/modules/pages/notifications/page.vue'), meta: { auth: true, webTrack: { moduleName: 'notifications', functionName: 'notificationsOpenIndex', needTraceId: false } } },
			{ path: '/legal/index', component: () => import('@/modules/pages/legal/index/page.vue'), meta: { auth: true, webTrack: { moduleName: 'legal', functionName: 'legalOpenIndex', needTraceId: false } } },
			{ path: '/legal/page/:id?', component: () => import('@/modules/pages/legal/page/page.vue'), meta: { auth: true, webTrack: { moduleName: 'legal', functionName: 'legalOpenPage', needTraceId: true } } },
			{ path: '/track/index', component: () => import('@/modules/pages/track/index/index.vue'), meta: { auth: true } },
			{ path: '/webTrack/index', component: () => import('@/modules/pages/webTrack/index/index.vue'), meta: { auth: true } },
			{ path: '/plastic_card/index', component: () => import('@/modules/pages/plasticCards/index/page.vue'), meta: { auth: false, webTrack: { moduleName: 'plasticCards', functionName: 'plasticCardsOpenIndex', needTraceId: false } } },
			{ path: '/contact_center/index', component: () => import('@/modules/pages/contact_center/page.vue'), meta: { auth: false, webTrack: { moduleName: 'contact_center', functionName: 'contact_centerOpenIndex', needTraceId: false } } },
			{ path: '/wallet/page/:id?', component: () => import('@/modules/pages/wallet/page/page.vue'), meta: { auth: true, webTrack: { moduleName: 'wallet', functionName: 'walletOpenPage', needTraceId: true } } },
			{ path: '/wallet/limitSetting/:id?', component: () => import('@/modules/pages/wallet/limitSetting/page.vue'), meta: { auth: true, webTrack: { moduleName: 'wallet', functionName: 'walletLimitSettingOpenPage', needTraceId: true } } },
			{ path: '/ticket/index', component: () => import('@/modules/pages/ticket/index/page.vue'), meta: { auth: false, webTrack: { moduleName: 'ticket', functionName: 'index', needTraceId: false } } },
			{ path: '/ticket/generate/index', component: () => import('@/modules/pages/ticket/generate/page.vue'), meta: { auth: false, webTrack: { moduleName: 'ticket', functionName: 'generateIndex', needTraceId: false } } },
			{ path: '/ticket/print/index', component: () => import('@/modules/pages/ticket/print/page.vue'), meta: { auth: false, webTrack: { moduleName: 'ticket', functionName: 'printIndex', needTraceId: false } } },
			{ path: '/ticket/manage/index', component: () => import('@/modules/pages/ticket/manage/page.vue'), meta: { auth: false, webTrack: { moduleName: 'ticket', functionName: 'manageIndex', needTraceId: false } } },
			{ path: '/ticket/page/:id', component: () => import('@/modules/pages/ticket/page/page.vue'), meta: { auth: false, webTrack: { moduleName: 'ticket', functionName: 'page', needTraceId: true } } },
			{ path: '/lookup/literCode/index', component: () => import('@/modules/pages/lookup/literCode/index/page.vue'), meta: { auth: false, webTrack: { moduleName: 'literCode', functionName: 'index', needTraceId: false } } },
			{ path: '/lookup/literCode/page/:id?', component: () => import('@/modules/pages/lookup/literCode/page/page.vue'), meta: { auth: false, webTrack: { moduleName: 'literCode', functionName: 'page', needTraceId: true } } },
			{ path: '/finance/transaction_wallet/index', component: () => import('@/modules/pages/finance/transaction_wallet/page.vue'), meta: { auth: false, webTrack: { moduleName: 'order', functionName: '/finance/transaction_wallet/index', needTraceId: false } } },
			{ path: '/finance/operation_legal/index', component: () => import('@/modules/pages/finance/operation_legal/page.vue'), meta: { auth: false, webTrack: { moduleName: 'order', functionName: '/finance/operation_legal/index', needTraceId: false } } },
			{ path: '/finance/operation_wallet/index', component: () => import('@/modules/pages/finance/operation_wallet/page.vue'), meta: { auth: false, webTrack: { moduleName: 'order', functionName: '/finance/operation_wallet/index', needTraceId: false } } }
		]
	},
	{
		path: '',
		component: () => import('@/modules/layouts/empty.vue'),
		children: [
			{ path: '/auth/login', component: () => import('@/modules/pages/auth/login/login.vue'), meta: { auth: false, webTrack: { moduleName: 'login', functionName: 'loginOpenPage', needTraceId: true } } },
			{ path: '/auth/register', component: () => import('@/modules/pages/auth/register/register.vue'), meta: { auth: false, webTrack: { moduleName: 'register', functionName: 'registerOpenPage', needTraceId: true } } }
		]
	}
]

const router = () => {
	const router = createRouter({
		history: createWebHistory(process.env.BASE_URL),
		routes
	})
	const authStore = useAuthStore()

	router.beforeEach((to, from, next) => {
		const requiredAuth = to.matched.some(r => r.meta.auth)
		const token = authStore.token
		if (requiredAuth && !token) next('/auth/login')
		else next()
	})
	router.afterEach((to, from) => {
		if (to.path == from.path) return
		if (to.meta?.webTrack) {
			const webTrackService = new WebTrackService()
			const webTrackRoute = to.meta.webTrack as IWebTrackRoute
			webTrackService.create(webTrackRoute)
		}
	})
	return router
}

export default router
